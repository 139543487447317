<template>
  <div class="footer-wrap">
    <div class="home-main">
      <div class="menu-wrap">
        <span
          class="menu-item cur-pointer"
          v-for="(item, index) in menus"
          :key="index"
          @click="toPages(item)"
          >{{ item.name }}</span
        >
      </div>
      <div class="contact-us">
        <span> 电话：010-86463634 </span>
        <span> 邮箱：service@qianlimu.com </span>
        <span> 地址：北京市海淀区上地信息路自主创新大厦6层6001室 </span>
      </div>

      <div class="qrcode-wrap">
        <span class="qrcode-title">千里目小程序</span>
        <div class="qrcode-content">
          <img class="qrcode" src="/images/home/qrcode.png" alt="" />
        </div>
      </div>
      <div class="record text-c">
        Copyright © 2022 Qianlimu.com All rights reserved.
        <a target="_blank" href="https://beian.miit.gov.cn"
          >苏ICP备2023018419号-4 |
        </a>
<!--        <a target="_blank">增值电信业务经营许可证编号：京B2-20210524</a>-->
        亿景智联（苏州）科技有限公司
      </div>
    </div>
  </div>
</template>
<script>
import { cloneObj } from "../assets/javascript/utils";
export default {
  data() {
    return {
      menus: [],
    };
  },
  methods: {
    toPages(item) {
      this.$router.push(item.path);
    },
  },
  mounted() {
    let menus = cloneObj(this.$store.state.menus);
    this.menus = menus.splice(1, menus.length);
  },
};
</script>
<style lang="less" scoped>
@import "../assets/stylesheets/config.less";
.footer-wrap {
  width: 100%;
  background: #1a1b24;
  padding-top: 78px;
  padding-bottom: 24px;
  height: 292px;
  .home-main {
    position: relative;
  }
  .menu-wrap {
    margin-bottom: 48px;
    .menu-item {
      font-size: 14px;
      margin-right: 76px;
      color: rgba(255, 255, 255, 0.8);
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .contact-us {
    font-size: 12px;
    margin-bottom: 60px;
    color: rgba(255, 255, 255, 0.6);
    span {
      margin-right: 100px;
    }
  }
  .qrcode-wrap {
    position: absolute;
    right: 0;
    top: -40px;
    width: 120px;
    height: 170px;
    text-align: center;
    .qrcode-content {
      background: #fff;
      border-radius: 3px;
      width: 120px;
      height: 120px;
      padding: 9px;
      margin-top: 18px;
    }
    .qrcode-title {
      font-weight: 500;
      color: #fafafa;
    }
    .qrcode {
      width: 100%;
      height: 100%;
    }
  }
  .record {
    margin-top: 20px;
    padding-top: 24px;
    color: rgba(250, 250, 250, 0.6);
    border-top: 1px solid rgba(255, 255, 255, 0.16);
    a {
      color: #2d8cf0;
    }
  }
}
</style>
