var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer-wrap" }, [
    _c("div", { staticClass: "home-main" }, [
      _c(
        "div",
        { staticClass: "menu-wrap" },
        _vm._l(_vm.menus, function (item, index) {
          return _c(
            "span",
            {
              key: index,
              staticClass: "menu-item cur-pointer",
              on: {
                click: function ($event) {
                  return _vm.toPages(item)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact-us" }, [
      _c("span", [_vm._v(" 电话：010-86463634 ")]),
      _c("span", [_vm._v(" 邮箱：service@qianlimu.com ")]),
      _c("span", [
        _vm._v(" 地址：北京市海淀区上地信息路自主创新大厦6层6001室 "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qrcode-wrap" }, [
      _c("span", { staticClass: "qrcode-title" }, [_vm._v("千里目小程序")]),
      _c("div", { staticClass: "qrcode-content" }, [
        _c("img", {
          staticClass: "qrcode",
          attrs: { src: "/images/home/qrcode.png", alt: "" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "record text-c" }, [
      _vm._v(" Copyright © 2022 Qianlimu.com All rights reserved. "),
      _c(
        "a",
        { attrs: { target: "_blank", href: "https://beian.miit.gov.cn" } },
        [_vm._v("苏ICP备2023018419号-4 | ")]
      ),
      _vm._v(" 亿景智联（苏州）科技有限公司 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }